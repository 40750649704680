@media(max-width: 991px){
    .cont1{
        width: 100%;
    }
    .cont2{
        width: 100%;
    }
}
@media(max-width: 768px){
    .main_container table thead{
        display: none;
    }
    .table_head_txt {
        display: block;
    }
    .main_container table tr{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 30px;
        border: 1px solid;
      }
    .main_container table td {
        vertical-align: middle;
        display: flex;
        width: 100%!important;
        max-width: 100%!important;
        border-bottom:1px solid #ccc;
        align-items: center;
    }
    .selectBox{
        display: block;
    }
    .search input, .search{
        width: 100%;
    }
    .main_container{
        margin: 70px 0 0 0;
    }
    .nav_button {
        margin-top: 6px;
    }
    .nav_button .icon-bar{
        transform: rotate(0) translateX(0);
    }
    .nav_button .bar2 {
        display: block;
    }
    header .left{
        transform:  translateX(-100%) translateX(55px);
    }
    .act_but.nav_button {
        margin-top: 12px;
    }
    .active_menu .nav_button .icon-bar {
        transition: all 0.3s;
        -webkit-transform: rotate(45deg) translateX(7px);
        transform: rotate(45deg) translateX(7px);
    }
    .active_menu .nav_button .bar2 {
        display: none;
    }
    .active_menu .nav_button .bar3 {
        transition: all 0.3s;
        -webkit-transform: rotate(-45deg) translateX(7px);
        transform: rotate(-45deg) translateX(7px);
    }
    .active_menu  .main_container{
        margin: 70px 0 0 0;
    }
    .active_menu header .left {
        -webkit-transform: translateX(0) translateX(0);
        transform: translateX(0) translateX(0);
    }
    /*.pagination li:first-child, .pagination li:last-child {*/
        /*display: none;*/
    /*}*/
}